import { ImageProps } from 'libs/growth-platform-brand-system-v2/src/types/module';
import { createSchema } from 'morphism';

export const schemaImageDisabledGatsby = createSchema<ImageProps, any>({
  alt: {
    path: 'alt',
    fn: (propertyValue: string, source: any) => {
      return propertyValue || source?.altDefault || '';
    },
  },
  src: {
    path: 'gatsbyImageData',
    fn: (propertyValue: any, source: any) => {
      return propertyValue?.images?.fallback?.src || source?.url;
    },
  },
  loading: {
    path: '',
    fn: () => {
      return 'lazy';
    },
  },
  width: 'width',
  height: 'height',
  sizes: {
    path: 'gatsbyImageData',
    fn: (propertyValue: any) => {
      return propertyValue?.images?.fallback?.sizes;
    },
  },
  srcSet: {
    path: 'gatsbyImageData',
    fn: (propertyValue: any) => {
      return propertyValue?.images?.fallback?.srcSet;
    },
  },
});
